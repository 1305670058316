import ReceiptInfo from "@/models/receipt-info";
import { formatAsCurrency } from "@/services/formatters";
import { Button, Card, CardBody, Col, Container, Row } from "react-bootstrap";

import Conditional from "@/components/conditional";
import ProjectTitleBar from "@/components/layout/project-title-bar";
import ScrollablePage from "@/components/scrollable-page";
import receiptApi from "@/services/apis/payment/receipt-api";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PaymentBreakdown from "./payment-breakdown";
import './receipt.scss';
import HelpSection from "@/components/help-section";

function Receipt() {
    const params = useParams() as any;
    const [receiptInfo, setReceiptInfo] = useState<ReceiptInfo>();

    useEffect(() => {
        if (!!params.paymentId)
            receiptApi.getReceipt(params.paymentId, setReceiptInfo);
    }, [params]);

    const printReceipt = () => {
        window.print();
    }

    return (
        <ScrollablePage className='f-receipt-page' title='Payment Receipt'>
            <ProjectTitleBar />
            <Row className="f-body-row">
                <Col xs="12" lg="8" className="f-receipt-col">
                    {!!receiptInfo &&
                        <Card className='f-card f-receipt-card'>
                            <CardBody>
                                <Container>
                                    <Row className="f-merchant-row">
                                        Transaction Receipt
                                    </Row>
                                    <Conditional condition={!!receiptInfo.BillingEmail}>
                                        <Row>
                                            {`A copy of this receipt has been emailed to ${receiptInfo.BillingEmail}`}
                                        </Row>
                                    </Conditional>
                                    <Row>
                                        <Col xs="12" lg="7">
                                            <Row className="f-header-row">Transaction Details</Row>
                                            <Row>{`Total Applied to Invoice:  ${formatAsCurrency(receiptInfo.InvoiceAmount)}`}</Row>
                                            {receiptInfo.ConvenienceFeeAmount > 0 &&
                                                <Row>{`Convenience Fee:  ${formatAsCurrency(receiptInfo.ConvenienceFeeAmount)}`}</Row>
                                            }
                                            <Row>{`Total Paid:  ${formatAsCurrency(receiptInfo.Amount)}`}</Row>
                                            <Row>{`Date:  ${receiptInfo.Date}`}</Row>
                                            <Row>{`Transaction ID:  ${receiptInfo.TransactionId}`}</Row>
                                        </Col>
                                        <Col xs="12" lg="5">
                                            <Row className="f-header-row">Billing Details</Row>
                                            <Row>{`Name:  ${receiptInfo.BillingName}`}</Row>
                                            <Row>{`Project:  ${receiptInfo.ProjectTitle}`}</Row>
                                            <Row>{`Invoice #:  ${receiptInfo.InvoiceNumber}`}</Row>
                                            <Row>{`Payment:  ${receiptInfo.PaymentType == 'ACH' ? 'Account' : 'Card'} Ending In ${receiptInfo.PaymentEndingNumber}`}</Row>
                                        </Col>
                                    </Row>
                                    <Row className="f-button-row">
                                        <Link className='btn btn-success f-link-button' to='/invoice'>Return to Invoice</Link>
                                        <Button className='f-print-button' variant="primary" onClick={printReceipt}>Print Receipt</Button>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>
                    }
                </Col>
                <Col xs="12" lg="4" className="f-breakdown-col">
                    <PaymentBreakdown screen="receipt" />
                    <HelpSection/>
                </Col>
            </Row>
        </ScrollablePage>
    );
}

export default Receipt;