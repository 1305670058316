import { FirmContext } from "@/contexts/firm-context-provider";
import { useContext } from "react";
import { Card, CardBody, Container, Row } from "react-bootstrap";

import './help-section.scss';

function HelpSection() {
    const firmContext = useContext(FirmContext);

    return (
        <Card className='f-card f-help-section'>
            <CardBody>
                <Row>
                    <span>This payment portal is managed by {firmContext.firm.MerchantName} and powered by Factor A/E - if you have questions regarding your invoicing or payment status, contact {firmContext.firm.MerchantName}.</span>
                </Row>
                <br/>
                <Row>
                <span>For questions regarding Factor, contact Factor at <a href="https://www.factorapp.com" target="_blank">factorapp.com</a></span>
                </Row>
            </CardBody>
        </Card>
    );
}

export default HelpSection;