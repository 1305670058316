import ProjectTitleBar from "@/components/layout/project-title-bar";
import ScrollablePage from "@/components/scrollable-page";
import PayFieldsComponent from "@/pages/billing/pay-fields";
import { Col, Row } from "react-bootstrap";
import './ach-payment.scss';
import PaymentBreakdown from "./payment-breakdown";
import HelpSection from "@/components/help-section";

function ACHPayment() {

    return (
        <ScrollablePage className='f-ach-payment' title='Pay With ACH'>
            <ProjectTitleBar />
            <Row className="f-body-row">
                <Col xs="12" lg="8" className="f-payfield-col">
                    <PayFieldsComponent paymentType='ach' />
                </Col>
                <Col xs={12} lg="4" className="d-none d-lg-block">
                    <PaymentBreakdown screen="payment" />
                    <HelpSection/>
                </Col>
            </Row>
        </ScrollablePage>
    );
}

export default ACHPayment;