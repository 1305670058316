import './scrollable-page.scss';

import { PropsWithChildren, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars-2";

interface Props extends PropsWithChildren<Record<never, any>> { 
    className: string;
    title?: string;
}

function ScrollablePage(props:Props) {
    useEffect(() => {
        document.title = props.title || "";
    }, [props.title]);

    return (
        <Scrollbars autoHide className={`f-scrollable-page ${props.className}`} renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }}></div>}>
            <div className='f-scrollable-page-body'>
                {props.children}
            </div>
        </Scrollbars>
    )
}

export default ScrollablePage;