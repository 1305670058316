interface Props {
    base64Pdf: string;
}

function PdfViewer(props: Props) {
    return (
        <div className='f-pdf-viewer'>
            <iframe src={`data:application/pdf;base64,${props.base64Pdf}`} width="100%" height="100%">
                <span>Failed to Load Document</span>
            </iframe>
        </div>
    )
}

export default PdfViewer;